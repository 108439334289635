import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import InlineLink from "../../../components/Chronology/Modules/InlineLink"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDotList from "../../../components/Chronology/Modules/ModDotList"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"
import ModVideo from "../../../components/Chronology/Modules/ModVideo"

export const frontmatter = {
  title: "Día 99",
  week: "Semana 15",
  day: "20",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-20",
  path: "/cronologia/semana-15#dia-20-jun/",
}

const Day99 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.450 casos
          mediante pruebas PCR. En ese mismo periodo, 101 personas han requerido hospitalización
          (7,0% de los diagnósticos), de los que seis han sido ingresos en la UCI, y se han
          producido 36 fallecimientos.
        </ModText>
        <ModText>
          Fernando Simón,{" "}
          <strong>
            Director del Centro de Coordinación de Alertas y Emergencias Sanitarias del Ministerio
            de Sanidad
          </strong>
          , ha explicado hoy cómo se recogen los datos epidemiológicos, quíen lo hace y cómo se ha
          gestionado durante la pandemia.
        </ModText>
        <ModVideo src="https://www.youtube.com/embed/XNAmfhGKQGA" />

        <ModText>
          La Unidad de Investigación en Cuidados y Servicios de Salud del ISCIII está desarrollando
          el{" "}
          <InlineLink link="https://www.isciii.es/Noticias/Noticias/Paginas/Noticias/EstudioSANICOVIInvesten.aspx">
            proyecto SANICOVI
          </InlineLink>{" "}
          para conocer qué factores están influyendo en la tasa de contagios por COVID-19 entre los
          profesionales sanitarios.
        </ModText>

        <ModText>
          A escala internacional, un{" "}
          <InlineLink link="https://www.isciii.es/Noticias/Noticias/Paginas/Noticias/EstudioNEJMCIBERGeneticaFalloRespiratorioCOVID19.aspx">
            estudio
          </InlineLink>{" "}
          con participación española ha observado que la vulnerabilidad de ciertas personas al
          desarrollo de formas clínicas graves en la infección por el virus SARS-COV-2 puede estar
          influenciada por sus características genéticas.
        </ModText>
        <ModText>
          En cuanto a las <strong>aplicaciones de rastreo y seguimiento de COVID-19</strong>,
          algunas empresas tecnológicas están encontrando oportunidades de negocio en países
          asiáticos, donde sus gobiernos están desarrollando Apps a medida para proteger la
          seguridad de los cuidadanos mientras reabren sus economías.
        </ModText>
        <ModDotList>
          Singapur <InlineLink link="https://www.tracetogether.gov.sg/">TraceTogether</InlineLink>
        </ModDotList>
        <ModDotList>
          Malasia{" "}
          <InlineLink link="https://play.google.com/store/apps/details?id=my.gov.onegovappstore.mytrace&hl=es_419">
            MyTrace
          </InlineLink>
        </ModDotList>
        <ModDotList>
          Australia <InlineLink link="https://www.covidsafe.gov.au/">COVIDSafe</InlineLink>
        </ModDotList>
        <ModDotList>
          Hong Kong{" "}
          <InlineLink link="https://apps.apple.com/es/app/stayhomesafe-app/id1499780720">
            SafeHomeSafe
          </InlineLink>
        </ModDotList>
        <ModDotList>
          Japón{" "}
          <InlineLink link="https://www.theverge.com/2020/6/19/21296603/japan-covid-19-contact-tracking-app-cocoa-released">
            COCOA
          </InlineLink>
        </ModDotList>
      </ContentRight>
    </LayoutDay>
  )
}
export default Day99
