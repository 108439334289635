import React from 'react';
import { Box } from '@sqymagma/elements'

export default({src}) => (
    src 
        ? (
            <Box my="m" width={1}>
            <iframe
                title={src}
                width="560"
                height="315"
                src={src}
                frameborder="0"
                allowfullscreen
            ></iframe>
            </Box>
        )
        : "Error with video source url"
)